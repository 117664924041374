import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

//layout
import Layout from "../../components/Layout";
import HeaderOrganization from "../../components/HeaderOrganization";

//static data
import { _JajaranDireksi } from "../../static/direksi";
import { __COTeam } from "../../static/teams";
import { __Finance } from "../../static/teams";

//translate
import { withTranslation } from "react-i18next";
import GlobalVariable from "../../components/GlobalVariable";
import { Helmet } from "react-helmet-async";
class Organization extends Component {
  state = {
    data: null,
    loading: true,
    error: null,
  };

  componentDidMount() {
    setTimeout(() => {
      fetch("https://rms.co.id/rms-api/api/organizations")
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            data: data.data,
            loading: false,
          });
          console.log(data);
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: "Failed to fetch data",
          });
        });
    });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Organization | RMS - Rukun Mitra Sejati</title>
          <meta
            name="description"
            content="RMS is FMCG Distributor Company Services Handling FMCG Supply Chain. "
          />
          <meta
            property="og:title"
            content="Organization | RMS - Rukun Mitra Sejati"
          />
          <meta
            property="og:description"
            content="RMS is FMCG Distributions Company Services with more than 10 Years experience to Handling FMCG Supply Chain."
          />
          <meta property="og:url" content="http://rms.co.id/organization" />
        </Helmet>
        <Layout>
          <HeaderOrganization />
          <main>
            <section id="komisioner">
              <Container className="col-main">
                <Row>
                  <h2 className="fw-bold text-center mt-5 wow animate__animated animate__fadeIn">
                    {this.props.t("title_commissioner")}
                  </h2>{" "}
                </Row>
                <br />
                {
                  <div className=" wow animate__animated animate__fadeIn">
                    <Row>
                      <div className="director-prim">
                        <img
                          className="director"
                          src={_JajaranDireksi[0].img}
                          alt=""
                        />
                        <div lg={6} md={5} className="identity-director">
                          <h4 className="title-content-director fw-bold">
                            {_JajaranDireksi[0].name}
                          </h4>
                          <p className="title-detail-director fw-bold">
                            {GlobalVariable.lang === "en"
                              ? _JajaranDireksi[0].position_en
                              : _JajaranDireksi[0].position}
                          </p>
                        </div>
                      </div>
                    </Row>
                    <Row className="my-2">
                      <p
                        className="text-muted"
                        style={{ marginTop: "15px", textAlign: "justify" }}
                      >
                        {"''"}

                        {GlobalVariable.lang === "en"
                          ? _JajaranDireksi[0].detail_en
                          : _JajaranDireksi[0].detail}
                        {"''"}
                      </p>
                    </Row>
                    <br />
                    <span />
                    <hr />
                  </div>
                }
              </Container>
            </section>
            <section id="direksi">
              <Container className="col-main">
                <Row>
                  <h2 className="fw-bold text-center mt-5 wow animate__animated animate__fadeIn">
                    {this.props.t("title_direction")}
                  </h2>{" "}
                </Row>
                <br />
                {
                  <div className=" wow animate__animated animate__fadeIn">
                    <Row>
                      <div className="director-prim">
                        <img
                          className="director"
                          src={_JajaranDireksi[1].img}
                          alt=""
                        />
                        <div lg={6} md={5} className="identity-director">
                          <h4 className="title-content-director fw-bold">
                            {_JajaranDireksi[1].name}
                          </h4>
                          <p className="title-detail-director fw-bold">
                            {GlobalVariable.lang === "en"
                              ? _JajaranDireksi[1].position_en
                              : _JajaranDireksi[1].position}
                          </p>
                        </div>
                      </div>
                    </Row>
                    <Row className="my-2">
                      <p
                        className="text-muted"
                        style={{ marginTop: "15px", textAlign: "justify" }}
                      >
                        {"''"}

                        {GlobalVariable.lang === "en"
                          ? _JajaranDireksi[1].detail_en
                          : _JajaranDireksi[1].detail}
                        {"''"}
                      </p>
                    </Row>
                    <br />
                    <span />
                    <hr />
                  </div>
                }
              </Container>
            </section>
            <section id="organization" className="my-5 text-center">
              <Container>
                <Row>
                  <h2 className="fw-bold text-center mt-5 wow animate__animated animate__fadeIn">
                    {this.props.t("title_organization")}
                  </h2>{" "}
                </Row>
                <br />
                <Card
                  style={{
                    marginBottom: "10px",
                  }}
                  className="organization-card"
                >
                  <Card.Body className="">
                    <div>
                      <Row>
                        <Col>
                          <Row
                            className="justify-content-md-center"
                            style={{ marginBottom: "1rem" }}
                          >
                            <Col>
                              <h2 className="fst-italic fw-bold text-center text-muted">
                                {this.props.t("title_core_team")}
                              </h2>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {/* <div className="organization-bg"></div> */}

                      {__COTeam.map((data, i) => (
                        <Row>
                          <img src={data.img} alt="" />
                        </Row>
                      ))}
                    </div>
                  </Card.Body>
                </Card>
                <Card
                  style={{
                    marginTop: "4rem",
                  }}
                  className="organization-card"
                >
                  <Card.Body className="">
                    <div>
                      <Row>
                        <Col>
                          <Row
                            className="justify-content-md-center"
                            style={{ marginBottom: "1rem" }}
                          >
                            <Col>
                              <h2 className="fst-italic fw-bold text-center text-muted">
                                {this.props.t("title_finance_team")}
                              </h2>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {/* <div className="organization-bg"></div> */}

                      {__Finance.map((data, i) => (
                        <Row>
                          <img src={data.img} alt="" />
                        </Row>
                      ))}
                      
                    </div>
                  </Card.Body>
                </Card>
              </Container>
            </section>
          </main>
        </Layout>
      </>
    );
  }
}

export default withTranslation()(Organization);
