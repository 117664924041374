import React, { useState, useRef, useEffect } from "react";
import { Carousel, Image, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import './Carousel.css'; // You may create this CSS file for styling

const ImageCarousel = ({ images }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handlePrev = () => {
    setIndex(index === 0 ? images.length - 1 : index - 1);
  };

  const handleNext = () => {
    setIndex(index === images.length - 1 ? 0 : index + 1);
  };

  return (
    <>
      <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
        {images.map((image, i) => (
          <Carousel.Item key={i} className="ratio ratio-4x3">
            <Image
              src={image}
              alt={`Slide ${i}`}
              className="object-fit-cover rounded"
              fluid
            />
          </Carousel.Item>
        ))}
      </Carousel>

      <Row className="mt-3">
        <Col>
          <CarouselThumbnails
            images={images}
            activeIndex={index}
            onSelect={handleSelect}
          />
        </Col>
      </Row>
    </>
  );
};

const CarouselThumbnails = ({ images, activeIndex, onSelect }) => {
  let sliderRef = useRef(null);


  useEffect(() => {
    // console.log(activeIndex);
    sliderRef.slickGoTo(activeIndex);
  }, [activeIndex]);

  // use effect??
  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  return (
    <Slider arrows={false}
      {...settings}
      ref={slider => {
        sliderRef = slider;
      }}
    >
      {images.map((image, i) => (
        <div
          key={i}
          className={
            i === activeIndex
              ? "border border-primary rounded ratio ratio-4x3"
              : " "
          }
          onClick={() => onSelect(i)}
        >
          <Image
            src={image}
            alt={`Thumbnail ${i}`}
            fluid
            className="p-2 rounded"
          />
        </div>
      ))}
    </Slider>
  );
};

export default ImageCarousel;
