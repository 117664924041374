import React, { Component } from "react";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import WOW from "wowjs";
import AppRouter from "./router";

export default class App extends Component {
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <BrowserRouter basename="/">
        <AppRouter/>
      </BrowserRouter>
    );
  }
}
