// //core team
// import coreTeam from "../assets/images/organization/core_team/CoreTeam-full.jpg";

// //finance
// import financeTeam from "../assets/images/organization/finance/FinanceTeam-full.jpg";

const coreTeam = "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/rms-upload/mqesjtaqbo9bftdujrzy";
const financeTeam = "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/rms-upload/qg5orxjemrbomt0obtpr";

export const __COTeam = [
  {
    name: "Core Team",
    img: coreTeam

  }
];

export const __Finance = [
  {
    name: "Finance Team",
    img: financeTeam
  }

];
