import Modal from "react-bootstrap/Modal";

export const BaseModal = ({ show, handleClose, content }) => {
  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      {/* <Modal.Header></Modal.Header> */}
      <Modal.Body>
        <div className="position-relative mb-3 p-4 border-bottom border-light">
          {/* <h5 className="modal-title text-primary fw-bold"></h5> */}
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0"
            onClick={handleClose}
          ></button>
        </div>
        <div>{content}</div>
      </Modal.Body>
      {/* <Modal.Footer>
        <button variant="secondary" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer> */}
    </Modal>
  );
};
