import React, { Component } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import CountUp from "react-countup";

//layout
import Layout from "../../components/Layout";

// static data
import { _coreValues } from "../../static/core_values";
import { _coreValues_id } from "../../static/core_values";

import { _listFinance, _listPrincipal } from "../../static/partners";
import { _Visi } from "../../static/visi_misi";
import { _Visi_id } from "../../static/visi_misi";

import { _Misi } from "../../static/visi_misi";
import { _Misi_id } from "../../static/visi_misi";

//translate
import { withTranslation } from "react-i18next";
import HistoryRMS from "../../components/HistoryRMS";
import GlobalVariable from "../../components/GlobalVariable";
import { Helmet } from "react-helmet-async";



class AboutUs extends Component {
  render() {
    const bahasa = GlobalVariable.lang;
    let core = "";
    let misi = "";
    let visi = "";

    if (bahasa === "id") {
      core = _coreValues_id;
      misi = _Misi_id;
      visi = _Visi_id;
    } else {
      core = _coreValues;
      misi = _Misi;

      visi = _Visi;
    }
    return (
      <>
        <Helmet>
          <title>About Us | RMS - Rukun Mitra Sejati</title>
          <meta
            name="description"
            content="The Trusted Partner for Sustainable Growth and Profitable Business"
          />
          <meta
            property="og:title"
            content="About Us | RMS - Rukun Mitra Sejati"
          />
          <meta
            property="og:description"
            content="The Trusted Partner for Sustainable Growth and Profitable Business"
          />
          <meta property="og:url" content="http://rms.co.id/about-us" />
        </Helmet>
        <Layout>
          <section className="text-white bg-mission">
            <Container className="py-5" style={{ marginBottom: "-10rem" }}>
              <Row className="justify-content-center py-5">
                {visi.map((data, i) => (
                  <Row
                    className="wow animate__animated animate__fadeIn"
                    data-wow-delay="0.25s"
                  >
                    <Card
                      style={{ width: "100%" }}
                      className="vision-card text-black"
                    >
                      <Card.Body className="text-center">
                        <h2 className="fw-bold" style={{ fontSize: "48px" }}>
                          {this.props.t("title_vision")}
                        </h2>

                        <div className="zoom-vision">
                          <p className="txt-vision text-muted fw-bold">
                            {data.title}
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Row>
                ))}

                <Row className="justify-content-center ">
                  <h2
                    className="text-center fw-bold"
                    style={{
                      marginBottom: "15px",
                      fontSize: "48px",
                    }}
                  >
                    {this.props.t("title_mission")}
                  </h2>
                  {misi.map((data, i) => (
                    <Row
                      className="list-inline-item event-list wow animate__animated animate__fadeIn"
                      data-wow-delay={`${(i * 2) / 10}s`}
                    >
                      <div className="text-center">
                        <Card
                          style={{
                            marginBottom: "10px",
                          }}
                          className="vision-card text-black text-muted fw-bold"
                        >
                          <Card.Body className="zoom-vision">
                            <p className="txt-vision">{data.title}</p>
                          </Card.Body>
                        </Card>
                      </div>

                      <div>
                        <Row>
                          {data.detail.map((datas) => (
                            <Col md={6} lg={3} style={{marginBottom:"20px"}}>
                              <Card className="mission-detail-card h-100" style={{marginBottom:"0"}}>
                                <Card.Body className="result-mission">
                                  <Row>
                                    <Col lg={10} md={9} xs={10}>
                                      <Card.Title className="txt-vision fw-bold">
                                        <h4>{datas.title}</h4>
                                      </Card.Title>
                                    </Col>
                                    <Col>
                                      <img
                                        alt=""
                                        src={datas.img}
                                        style={{
                                          position: "absolute",
                                          right: "16px",
                                          width: "40px",
                                          top:"10%"
                                        }}
                                      ></img>
                                    </Col>
                                  </Row>
                                  <Card.Text className="overlay-detail-mission">
                                    <h6 className="detail-mission">
                                      {datas.content}
                                    </h6>
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </Row>
                  ))}
                </Row>
              </Row>
              <h2 className="text-center fw-bold mt-5 wow animate__animated animate__fadeIn">
                {this.props.t("title_core_values")}
              </h2>
              <Row className="mb-5 justify-content-center ">
                {core.map((data, i) => {
                  return (
                    <Col
                      md={6}
                      lg={5}
                      key={i}
                      className="my-5 text-center wow animate__animated animate__fadeIn"
                      data-wow-delay={`${(i * 2) / 10}s`}
                    >
                      <img src={data.img} alt="" className="img-about"/>
                      <h4 className="fw-bold mt-3">{data.title}</h4>
                      <p style={{ width: "70%", margin: "auto" }}>
                        {data.content}
                      </p>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </section>
          <HistoryRMS />
          <section className="partners bg-alabaster">
            <Container className="partners py-5 bg-alabaster">
              <Row>
                <Col className="my-5">
                  <h2 className="fw-bold text-center">
                    {this.props.t("title_partner")}
                  </h2>
                  <Row style={{ marginTop: "3rem" }}>
                    <Col md={6} style={{ marginBottom: "2rem" }}>
                      <h5 className="fw-bold my-4">
                        <span />
                        {this.props.t("desc_partner")}
                      </h5>
                      <h2 className="fw-bold my-4">
                        <span />
                        {this.props.t("amount_partner")}
                      </h2>
                      <h2 className="mb-0 fw-bolder">
                        <CountUp
                          className="text-primary"
                          end={50000}
                          duration={2}
                          delay={0.8}
                        />
                      </h2>

                      <br></br>
                      <p className="text-muted justified-paragraph" style={{fontSize:"16px"}}>
                        <span />
                        {this.props.t("detail_partner")}
                      </p>
                    </Col>
                    <Col md={6}>
                      <Row className="justify-content-center">
                        <h3 className="fw-bold text-center">
                          {this.props.t("title_principal_partner")}
                        </h3>
                        <Row className="justify-content-center">
                          {_listPrincipal.map((data, i) => (
                            <Col
                              key={i}
                              xs={4}
                              className=" align-self-center text-center wow animate__animated animate__fadeIn"
                              data-wow-delay={`${(i * 2) / 10}s`}
                            >
                              <Card className="partner partner-hover">
                                <Card.Body>
                                  <a
                                    href={data.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={data.img}
                                      alt=""
                                      className="logo-partners"
                                    />
                                  </a>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Row>
                      <br></br>
                      <Row>
                        <h3 className="fw-bold text-center text-center">
                          {this.props.t("title_strategic_partner")}
                        </h3>
                        <Row className="justify-content-center">
                          {_listFinance.map((data, i) => (
                            <Col
                              xs={4}
                              key={i}
                              className="align-self-center text-center wow animate__animated animate__fadeIn"
                              data-wow-delay={`${(i * 2) / 10}s`}
                            >
                              <Card className="partner">
                                <Card.Body>
                                  <a
                                    href={data.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={data.img}
                                      alt=""
                                      className="logo-strategic"
                                    />
                                  </a>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </Layout>
      </>
    );
  }
}
export default withTranslation()(AboutUs);
