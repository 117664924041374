//layout
import React from 'react'
import ReactDOM from 'react-dom'
import FooterRMS from "./FooterRMS";
import NavbarRMS from "./NavbarRMS";

import GlobalVariable from './GlobalVariable';
console.log(GlobalVariable.lang);

const Layout = ({ children }) => {
	return (
		<section>
			<NavbarRMS customize search="none" iconAdd="none" />
			{children}
			<FooterRMS />
		</section>
	);
};

export default Layout