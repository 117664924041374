import React, { Component } from "react";

//layout
import Layout from "../../components/Layout";
import SectionSocmed from "../../components/SectionSocmed";
import { Helmet } from "react-helmet-async";
export default class ContactUs extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Contact Us | RMS - Rukun Mitra Sejati</title>
          <meta
            name="description"
            content="RMS is FMCG Distributions Company Services with more than 10 Years experience to Handling FMCG Supply Chain."
          />
          <meta
            property="og:title"
            content="Contact Us | RMS - Rukun Mitra Sejati"
          />
          <meta
            property="og:description"
            content="RMS is FMCG Distributions Company Services with more than 10 Years experience to Handling FMCG Supply Chain."
          />
          <meta property="og:url" content="http://rms.co.id/contact-us" />
        </Helmet>
        <Layout>
          <section className="bg-alabaster mt-4">
            <SectionSocmed />
          </section>
        </Layout>
      </>
    );
  }
}
