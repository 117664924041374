import React, { useState } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { HashLink } from "react-router-hash-link";

//assets
import mapIndonesia from "../assets/images/new-indo-map.gif";

//translate
import { useTranslation } from "react-i18next";

function RMSNetwork() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <section className="py-5">
        <Container style={{ paddingBottom: "15rem" }}>
          <Row className="wow animate__animated animate__fadeInUp">
            <Col md={5} lg={4} className="align-self-center my-2">
              <h2 className="fw-bold">
                <span className="bl-2" />
                {t("title_rms_network")}
              </h2>
              <Row className="mt-5" style={{ marginLeft: "20px" }}>
                <Col xs={6} className="my-2">
                  <div className="hover-warehouse">
                    <HashLink
                      to={"/branch#branch"}
                      style={{ textDecoration: "none" }}
                    >
                      <h1 className="text-primary mb-0 fw-bolder">
                        <CountUp end={100} duration={1} delay={0.5} /> +
                      </h1>
                    </HashLink>
                    <h5 className="fw-normal">{t("warehouse_rms_network")}</h5>
                  </div>
                </Col>

                <Col xs={6} className="my-2">
                  <Link to={"/organization"} style={{ textDecoration: "none" }}>
                    <h1 className="text-primary mb-0 fw-bolder">
                      <CountUp
                        end={2500}
                        duration={1}
                        delay={0.5}
                        separator="."
                      />{" "}
                      +
                    </h1>
                    <h5 className="fw-normal text-black">
                      {t("employees_rms_network")}
                    </h5>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col md={7} lg={8} className="align-self-center text-center my-2">
              <Row className="maps-row">
                <img src={mapIndonesia} alt="" className="img-fluid" />
                <Button
                  onClick={() => handleShow(!show)}
                  className="hover-maps"
                  variant="noBg"
                ></Button>
              </Row>
            </Col>
          </Row>

          {/* Modal For Detail Office */}
          <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Head Office PT Rukun Mitra Sejati</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                maxHeight: "calc(100vh - 210px)",
                overflowY: "auto",
              }}
            >
              <iframe
                title="Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d698.5290402304946!2d112.64660062026525!3d-7.949561599993876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd62998d36e9737%3A0x4e58aca3565bd992!2sPT.%20Rukun%20Mitra%20Sejati%20(BOOM%20Group)!5e0!3m2!1sid!2sid!4v1697169519816!5m2!1sid!2sid"
                width="100%"
                height="450"
                style={{ border: "0" }} 
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Modal.Body>
          </Modal>
        </Container>
      </section>
    </>
  );
}

export default RMSNetwork;
