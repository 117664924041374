import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import React from 'react'

// static data
import { _Testimonial } from "../static/testimonial";
import { _Testimonial_en } from "../static/testimonial";


//translate
import { useTranslation } from "react-i18next";
import GlobalVariable from "./GlobalVariable";

function TestimonialRMS() {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const bahasa = GlobalVariable.lang;
  let testi = '';

  if (bahasa === 'id') {
    testi = _Testimonial;

  } else {
    testi = _Testimonial_en;

  }

  return (
    <section>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {testi.map((data, i) => (
          <Carousel.Item>
            <Col
              lg={{ offset: 1 }}
              className="wow animate__animated animate__fadeIn"
              data-wow-delay={`${(i * 2) / 10}s`}
            >
              <h2 className="fw-bold my-4 text-white">
                {t("title_testimonial")}
              </h2>
              <br />
            </Col>

            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Col
                md={4}
                lg={{ span: 3 }}
                sm={4}
                className="wow animate__animated animate__fadeInRight"
                data-wow-delay={`${(i * 2) / 10}s`}
              >
                <img className="testimonial-img" src={data.img} alt="" />
              </Col>
              <Col
                md={8}
                lg={{ span: 6 }}
                sm={{ span: 8 }}
                className="wow animate__animated animate__fadeInRight"
                data-wow-delay={`${(i * 2) / 10}s`}
              >
                <br />

                <p className="text-white justified-paragraph"><i>"{data.content}"</i></p>
                <br />
                <p className="text-white fw-bold"> - {data.name} -</p>
                <p className="text-white fw-bold">{data.company}</p>
              </Col>
            </Row>

            <br />
            <br />
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
}

export default TestimonialRMS;
