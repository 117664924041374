import iconSpeed from "../assets/images/core-values/speed.svg";
import iconFlexibility from "../assets/images/core-values/flexibility.svg";
import iconEmpowering from "../assets/images/core-values/empowering.svg";
import iconCollaboration from "../assets/images/core-values/collaboration.svg";

export const _coreValues = [
  {
    title: "Speed",
    content: "Excellent and effectiveness distribution process to the hands of consumers.",
    img: iconSpeed,
  },
  {
    title: "Flexibility",
    content: "Adapt to change and new ways of working quickly and easily.",
    img: iconFlexibility,
  },
  {
    title: "Empowering",
    content: "Empowering to achieve productivity, better employee engagement, enhanced customer experience, and improved work culture.",
    img: iconEmpowering,
  },
  {
    title: "Collaboration",
    content: "Collaboration and team work to approach any problem from diverse angles and find more creative solutions.",
    img: iconCollaboration,
  },
];


export const _coreValues_id = [
  {
    title: "Cepat",
    content: "Proses distribusi yang sangat baik dan efektif sampai ke tangan konsumen.",
    img: iconSpeed,
  },
  {
    title: "Fleksibel",
    content: "Beradaptasi dengan perubahan dan cara kerja baru dengan cepat dan mudah.",
    img: iconFlexibility,
  },
  {
    title: "Pemberdayaan",
    content: "Memberdayakan untuk mencapai produktivitas, keterlibatan karyawan yang lebih baik, pengalaman pelanggan yang lebih baik, dan budaya kerja yang lebih baik.",
    img: iconEmpowering,
  },
  {
    title: "Kolaborasi",
    content: "Kolaborasi dan kerjasama tim untuk menyelesaikan semua permasalahan dari berbagai sudut pandang dan menemukan solusi yang lebih kreatif.",
    img: iconCollaboration,
  },
];
