import React, { useEffect, useState } from "react";
import { Container, Row, Card, Col } from "react-bootstrap";

//static data
import { _Warehouse } from "../static/warehouse";

// icons
import { FaLongArrowAltRight } from "react-icons/fa";

import ModalRMS from "./ModalRMS";

const SubMenuItem = ({ subItem, click, openMap, getDataForMap }) => {
  return (
    <Col className="click-warehouse-detail">
      <div class="d-flex flex-column flex-md-row justify-content-between">
        <div onClick={click} class="w-100">
          <h5 className="text-white fw-bold">{subItem.nama_cabang}</h5>
          <p className="text-white">{subItem.alamat}</p>
        </div>
        <button type="button" class="btn btn-outline-primary text-white btn-sm" onClick={() => {
          openMap();
          getDataForMap();
        }}>Lihat Lokasi</button>
      </div>
      <hr style={{ height: "2px", color: "white" }} />
    </Col>
  );
};

const BranchRMS = () => {
  const [model, setModel] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [tempdata, setTempdata] = useState([]);

  const [cabang, setCabanng] = useState([]);

  const getData = (name, img, map) => {
    let tempData = [name, img, map];
    setTempdata((dataCabang) => [1, ...tempData]);
  };

  const [activeMenu, setActiveMenu] = useState(null);

  const handleMenuItemClick = (itemId) => {
    if (itemId === activeMenu) {
      setActiveMenu(null);
    } else {
      setActiveMenu(itemId);

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    async function fetchData() {
      fetch("https://rms.co.id/rms-api/api/cabang", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCabanng(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <section id="branch" className="mt-4">
        <Container
          className="py-5 text-black"
          style={{ marginBottom: "-10rem" }}
        >
          <Row
            className="wow animate__animated animate__fadeIn"
            data-wow-duration="2s"
          >
            <Col lg={4} md={6} xs={6}>
              <div>
                {cabang.map((data, i) => (
                  <Card
                    key={data.id}
                    className={`menu-item-warehouse ${data.id === activeMenu ? "active" : ""
                      }`}
                    onClick={() => handleMenuItemClick(data.id)}
                  >
                    <Card.Body>
                      <Row>
                        <Col lg={8} xs={10}>
                          <h7 className="text-white fw-bold">
                            Provinsi {data.nama_provinsi}
                          </h7>
                        </Col>
                        <Col>
                          <FaLongArrowAltRight
                            className="ms-3 arrow-branch"
                            style={{
                              position: "absolute",
                              right: "30px",
                              color: "white",
                            }}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </Col>
            <Col>
              <div className="submenu-container-warehouse">
                {cabang.map((menuItem, i) => (
                  <div
                    key={menuItem.id}
                    className={`submenu-content-warehouse ${menuItem.id === activeMenu ? "active" : ""
                      }`}
                  >
                    {menuItem.get_cabang && (
                      <div>
                        {menuItem.get_cabang.map((subItem) => (
                          <div>
                            <SubMenuItem 
                              key={subItem.id} 
                              openMap={() => setMapModal(true)} 
                              subItem={subItem} 
                              click={() => {
                                getData(subItem.nama_cabang, `https://rms.co.id/rms-api/storage/app/public/images/cabang/${subItem.image}`, subItem.map_src);
                                setModel(true);
                              }} 
                              getDataForMap={() => getData(subItem.nama_cabang, `https://rms.co.id/rms-api/storage/app/public/images/cabang/${subItem.image}`, subItem.map_src)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
        {model === true ? (
          <ModalRMS
            name={tempdata[1]}
            type='img'
            img={tempdata[2]}
            hide={() => setModel(false)}
          />
        ) : mapModal ? <ModalRMS
          name={tempdata[1]}
          type='map'
          map={tempdata[3]}
          hide={() => setMapModal(false)}
        /> : (
          ""
        )}
      </section>
    </>
  );
};

export default BranchRMS;
