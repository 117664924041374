import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import axios from "axios";

// static data
import { _newsBoom } from "../static/news";

//translate
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../i18n.jsx";
import { HighlightNews } from './RMSNewsHighlight.jsx';
import RMSOtherNews from "./RMSOtherNews.jsx";

// axios.defaults.baseURL =
//   "https://newsapi.org/v2/everything?q=boom&apiKey=d365b4a7c584425da4b8ca1a856345b6";

const RMSNews = () => {
  const { t } = useTranslation();
  const [loading, setloading] = useState(true);
  const [news, setNews] = useState([]);

  const fetchData = () => {
    setloading(true);
    axios
      .get("https://adm-db-cms.rms.co.id/api/news")
      .then((res) => {
        setNews(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const sortedNews = news ? news.sort((a, b) => {
    const a_NewsUpload = a.news_uploaded.split(' ');
    const b_NewsUpload = b.news_uploaded.split(' ');
    
    return new Date(b_NewsUpload) - new Date(a_NewsUpload);
  }) : [];


  // console.log(sortedNews);

  return (
    <section className="py-5  wow animate__animated animate__fadeIn">
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }} className="text-center">
            <h2 className="fw-bold">{t("title_rms_news")}</h2>            
          </Col>
        </Row>
        <HighlightNews />
        <Row className="mt-5">
          {loading === false ? (
            sortedNews.map((data, i) => (
              <Col md={4} className="my-3" key={i}>
                <Card className="news">
                  <a href={data.url} target="_blank" rel="noreferrer">
                    {                      

                      data.is_news_from_admin === 1 ? (
                        <div style={{ backgroundImage: `url(http://localhost/storage/app/public/images/news/${data.image})` }} className="news-img" />
                      ) : (
                        <div style={{ backgroundImage: `url(${data.image_url})` }} className="news-img" />
                      )
                    }
                  </a>
                  <Card.Body>
                    <h5 className="fw-bold">{i18n.language === 'id' ? data.title.substring(0, 120) : data.title_en == null ? data.title : data.title_en.substring(0, 120)}</h5>
                    <p className="text-muted">
                      { i18n.language === 'id' ? data.content.substring(0, 120) : data.content_en == null ? data.content : data.content_en.substring(0, 120)}
                      {"... "}
                      {data.is_news_from_admin === 1 ? (
                        <Link
                          to={`/news/${data.slug}`}
                          target="_blank"
                          rel="noreferrer"
                          className={`${data.slug}`}
                        >
                          {t("more_rms_news")}
                        </Link>
                      ) : (
                        <a href={data.url} target="_blank" rel="noreferrer">
                          {t("more_rms_news")}
                        </a>
                      )} 
                    </p>
                    {/* x for delete */}
                    {data.get_other_sources.length > 0 && data.get_other_sources.every((otherSrc) => otherSrc.news_portal_name !== 'x') ? <RMSOtherNews news={data.get_other_sources} /> : null }
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col md={4} className="my-3">
              <Card className="news loading-skeleton">
                <a href="" target="_blank" rel="noreferrer">
                  <div style={{ backgroundImage: "" }} className="news-img" />
                </a>
                <Card.Body>
                  <h5 className="fw-bold"></h5>
                  <p className="text-muted">
                    <Link to="" target="_blank" rel="noreferrer"></Link>
                  </p>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default RMSNews;
