import { Container, Row, Card, Col } from "react-bootstrap";
import React from 'react'


function ModalRMS(props) {
  let modelStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.8)",
  };
  return (
    <>
      <div
        class="modal"
        tabindex="-1"
        className="modal show fade"
        style={modelStyle}
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{props.type === 'img' ? `${props.name}` : `LOKASI ${props.name}`}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={props.hide}
              ></button>
            </div>
            <div class="modal-body">
              <Container fluid>
                {props.type === 'img' ? <img src={props.img} alt="" className="img-fluid"></img> : <iframe 
                title="Map"
                src={props.map} 
                width="100%" 
                height="450" 
                style={{ border: "0" }} 
                allowfullscreen 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
                </iframe>}
              </Container>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default ModalRMS;
