import { Col, Container, Row, Card, Carousel } from "react-bootstrap";
import axios from "axios";
import React, { useEffect, useState } from "react";

//static data
// import { _achievement } from "../static/achievement";

//translate
import { useTranslation } from "react-i18next";
import i18n from "../i18n.jsx";

function RMSAchievement() {
  const { t } = useTranslation();
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    axios
      .get("https://rms.co.id/rms-api/api/portofolios")
      .then((res) => {
        setPortfolio(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <section style={{ backgroundColor: "#f2f5f7" }}>
        <Container className="py-5">
          <h2 className="text-center fw-bold mt-5 wow animate__animated animate__fadeIn">
            {t("title_achievement")}
          </h2>
          <Container className="mt-5">
            {portfolio.map((data) => (
              <div>
                {data.get_detail.slice(0, 1).map((datas) => (
                  <Row>
                    <Col
                      md={6}
                      lg={{ span: 5, offset: 1 }}
                      className="my-5 wow animate__animated animate__fadeInLeft"
                      data-wow-duration="1s"
                      style={{ paddingLeft: "20px", paddingRight: "20px" }}
                    >
                      <h3 className="fw-bold">{i18n.language === 'id' ? data.judul : data.judul_en}</h3>
                      <br />
                      <p className="text-muted justified-paragraph">{i18n.language === 'id'  ? data.isi : data.isi_en}</p>
                      <Carousel
                        controls={false}
                        indicators={false}
                        interval={3000}
                      >
                        {data.get_detail.map((dataCarousel) => (
                          <Carousel.Item className="slide">
                            <img
                              src={`https://rms.co.id/rms-api/storage/app/public/images/portofolio/${dataCarousel.gambar}`}
                              className="d-block w-100"
                              alt=""
                              style={{ height: "330px" }}
                            />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </Col>
                    <Col
                      md={6}
                      lg={5}
                      className="align-self-center my-4 wow animate__animated animate__fadeInRight"
                      data-wow-duration="1s"
                    >
                      <Row>
                        {data.get_detail.map((dataDetail, i) => (
                          <Col
                            xs={6}
                            className="wow animate__animated animate__fadeIn"
                            data-wow-delay={`${(i * 2) / 10}s`}
                            style={{marginBottom:"10px"}}

                          >
                            <Card
                              style={{
                                marginLeft: "-5px",
                                marginRight: "-5px",
                                backgroundColor:"#F7F7F7"
                              }}
                              className="h-100"
                            >
                              <div className="zoom-achievement">
                                <Card.Img
                                  variant="top"
                                  src={`https://rms.co.id/rms-api/storage/app/public/images/portofolio/${dataDetail.gambar}`}
                                  className="img-achievement"
                                />
                              </div>
                              <Card.Body>
                                <p className="text-muted text-center" style={{marginBottom: "0%"}}>
                                  {i18n.language == 'id' ? dataDetail.judul : dataDetail.judul_en}
                                </p>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <hr />
                  </Row>
                ))}
              </div>
            ))}
          </Container>
        </Container>
      </section>
    </>
  );
}

export default RMSAchievement;
