import testi1 from "../assets/images/testimoni/rms-testimoni-nestle.jpg"
import testi2 from "../assets/images/testimoni/rms-testimoni-perfetti.jpg"
import testi3 from "../assets/images/testimoni/rms-testimoni-unicharm.jpg"
import testi4 from "../assets/images/testimoni/rms-charm.jpeg"



export const _Testimonial = [
    {
      name: "Octorio Labriyanca Subagyo",
      img: testi1,
      company: "Nestle Indonesia",
      img_company:"",
      content:"RMS adalah distributor nomor 1 di market GT (General Trade) Indonesia. Komunikasi & Agresifitas Expansi yang menjadi kunci utama RMS semakin terus bertumbuh dan menjadi longterm partnership bagi seluruh mitra kerjanya. Cheers!"
    },
    {
      name: "Abdullah",
      img: testi2,
      company: "Regional Business Manager, PT. Perfetti Van Melle Indonesia",
      img_company:"",
      content:"PT Rukun Mitra Sejati merupakan perusahaan distribusi yang sangat agresif dalam mengembangkan dan memperluas cakupan areanya. Sangat peduli dengan nilai -nilai dasar distribusi sebagai kunci keberhasilan distribusi yang terus berkembang dan menjadi mitra bisnis jangka panjang yang saling menguntungkan bagi semua mitranya."
    },
    {
      name: "Sri Haryani",
      img: testi3,
      company: "Sales Director, PT Uni-Charm Indonesia TBK",
      img_company:"",
      content:"Sejak tahun 2019 dimulainya kerjasama dengan RMS di area Jawa Timur. Melalui komitmen dan kemampuan distribusi yang sangat baik sehingga kerjasama diperluas di wilayah lainnya baik pulau Jawa dan luar Jawa. Dengan kolaborasi yang solid antara Distributor dan Principal untuk mendistribusikan produk produk unggulan dari PT Unicharm Indonesia TBK seluruh toko. Dan pastinya akan mewujudkan pertumbuhan bisnis yang berkelanjutan."
    },
    {
      name: "Henip Arizona",
      img: testi4,
      company: "Vice Sales Director PT Uni - Charm Indonesia Tbk",
      img_company:"",
      content:"RMS adalah distributor yang memiliki komitmen tinggi dalam meraih pencapain baik target penjualan maupun distribusi. Organisasi dan SOP menjadi kunci kekuatan dari RMS. Semoga senantiasa tumbuh bersama PT Unicharm serta berdampak nyata utk Gerakan Indonesia Emas 2045"
    },
  ];
  

  export const _Testimonial_en = [
    {
      name: "Octorio Labriyanca Subagyo",
      img: testi1,
      company: "Nestle Indonesia",
      img_company:"",
      content:"RMS is the number one  distributor in Indonesia GT (General Trade) market. Communication & Aggressive Expansion are the main keys for RMS to continue to grow and become a longterm partnership for all its partners. Cheers!"
    },
    {
      name: "Abdullah",
      img: testi2,
      company: "Regional Business Manager, PT. Perfetti Van Melle Indonesia",
      img_company:"",
      content:"PT Rukun Mitra Sejati is a distribution company that is very aggressive in developing and expanding its coverage area. Very concerned with the basic fundamentals of distribution is the key to the success of distribution that continues to grow and become a mutually beneficial long-term business partner for all its partners."
    },
    {
      name: "Sri Haryani",
      img: testi3,
      company: "Sales Director, PT Uni-Charm Indonesia TBK",
      img_company:"",
      content:"Since 2019, the collaboration with RMS began in the East Java area. Through commitment and excellent distribution capabilities, cooperation was expanded in other regions both in Java and outside Java. With solid collaboration between Distributors and Principals to distribute superior products from PT Unicharm Indonesia TBK throughout the store. And will certainly realize sustainable business growth."
    },
    {
      name: "Henip Arizona",
      img: testi4,
      company: "Vice Sales Director PT Uni - Charm Indonesia Tbk",
      img_company:"",
      content:"RMS is a distributor that holds a strong commitment to achieving both sales and distribution targets. Organization and SOP are the key strengths of RMS. Hopefully, it will continue to grow together with PT Unicharm and have a tangible impact on the Indonesia Emas 2045 movement."
    },
  ];
  