import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import Cookies from "js-cookie";

const AnnouncementBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  //   Cookies.set('bannerClose', false);

  const closeBanner = () => {
    Cookies.set("bannerClose", true);
    setShowBanner(false);
    console.log(bannerClose);
  };

  const bannerClose = Cookies.get("bannerClose");

  console.log(bannerClose);

  const isBannerAbleToOpen = (bannerClose === "false" || !bannerClose) && showBanner;

  const bannerStyle = {
    transform: `translate(0, -15px)`, 
  };

  return (
    <div>
      {isBannerAbleToOpen && (
        <div
          style={bannerStyle}
          className="z-3 position-absolute w-100 bg-primary d-flex p-1 align-items-center justify-content-between bg-none"
        >
          <div className="mt-4 pb-2">
            <span className="text-white px-4 border-0 fs-6">
              Read our latest news!
              <a href="/news" onClick={closeBanner} className="text-white ms-2">
                Click Here
              </a>
            </span>
          </div>
          <div className="mt-3">
            <Button onClick={closeBanner}>
              <i className="fa fa-xmark text-white "></i>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnouncementBanner;
