import { Container } from "react-bootstrap";
import Layout from "../../components/Layout";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RMSNews from "../../components/RMSNews";
import { Helmet } from "react-helmet-async";
const News = () => {
  const params = useParams();
  const { slug } = params;
  const [news, setNews] = useState([]);

  // const fetchNews = () => {
  //     fetch(`http://localhost:8000/api/news/${slug}`)
  //         .then((response) => response.json())
  //         .then((data) => {
  //             setNews(data.data);
  //         })
  //         .catch((error) => {
  //             console.error("Error:", error);
  //         });
  // };

  // useEffect(() => {
  //     fetchNews();
  // },[])
  return (
    <>
      <Helmet>
        <title>News | RMS - Rukun Mitra Sejati</title>
        <meta
          name="description"
          content="RMS is FMCG Distributions Company Services with more than 10 Years experience to Handling FMCG Supply Chain."
        />
        <meta
          property="og:title"
          content="News | RMS - Rukun Mitra Sejati"
        />
        <meta
          property="og:description"
          content="RMS is FMCG Distributions Company Services with more than 10 Years experience to Handling FMCG Supply Chain."
        />
        <meta property="og:url" content="http://rms.co.id/news" />
      </Helmet>
      <Layout>
        <section style={{ paddingBottom: "10rem" } } className="mt-4">
          <Container>
            <RMSNews />
            {/* <Row className="mx-2 mt-4">
                        <div>
                            <h3 className="">{news.title}</h3>
                            <p className="text-muted">{} - Admin</p>
                        </div>
                        <div className="mt-5">
                            {news.content}
                        </div>
                    </Row> */} 
          </Container>
        </section>
      </Layout>
    </>
  );
};

export default News;
