import React, { Component } from "react";

//layout
import Layout from "../../components/Layout";
import BranchRMS from "../../components/BranchRMS";
import { Helmet } from "react-helmet-async";
//translate
import { withTranslation } from "react-i18next";

class Branch extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Branch | RMS - Rukun Mitra Sejati</title>
          <meta
            name="description"
            content="RMS is FMCG Distributions Company Services with more than 10 Years experience to Handling FMCG Supply Chain."
          />
          <meta
            property="og:title"
            content="Branch | RMS - Rukun Mitra Sejati"
          />
          <meta
            property="og:description"
            content="RMS is FMCG Distributions Company Services with more than 10 Years experience to Handling FMCG Supply Chain."
          />
          <meta property="og:url" content="http://rms.co.id/branch" />
        </Helmet>
        <Layout>
          <section className="text-white bg-mission">
            <BranchRMS />
          </section>
        </Layout>
      </>
    );
  }
}
export default withTranslation()(Branch);
