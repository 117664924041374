import { Container } from "react-bootstrap";
import React from 'react'

// image
import sosmed1 from "../assets/images/socmed/logo-fb.svg";
import sosmed2 from "../assets/images/socmed/logo-youtube.svg";
import sosmed3 from "../assets/images/socmed/logo-instagram.svg";
import sosmed4 from "../assets/images/socmed/logo-linkedin.svg";

//translate
import { useTranslation } from "react-i18next";

const SectionSocmed = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <Container className="text-center">
        <p>{t("title_rms_social_media")}</p>
        <h2 className="fw-bold wow animate__animated animate__fadeIn">
          {t("rms_social_media")}
        </h2>
        <ul className="list-cta list-socmed mt-5">
          <li
            className="wow animate__animated animate__fadeIn"
            data-wow-delay="0.6s"
          >
            <a href="https://www.instagram.com/rukun_mitra_sejati/?hl=id">
              <img className="img-sosmed" src={sosmed3} alt=""/>
            </a>
          </li>
          <li
            className="wow animate__animated animate__fadeIn"
            data-wow-delay="0.2s"
          >
              <a href="https://www.facebook.com/profile.php?id=61550336082276">
              <img className="img-sosmed" src={sosmed1} alt=""/>
              </a>
          </li>
          <li
            className="wow animate__animated animate__fadeIn"
            data-wow-delay="0.4s"
          >
            <a href="https://www.youtube.com/@OfficialPTRukunMitraSejati">
              <img className="img-sosmed" src={sosmed2} alt=""/>
            </a>
          </li>
          <li
            className="wow animate__animated animate__fadeIn"
            data-wow-delay="0.8s"
          >
            <a href="https://www.linkedin.com/in/pt-rukun-mitra-sejati-rms-184100288">
              <img className="img-sosmed" src={sosmed4} alt=""/>
            </a>
          </li>
        </ul>
      </Container>
    </section>
  );
};

export default SectionSocmed;
