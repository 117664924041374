import React, { useState } from "react";
import { Row, Container, Col } from "react-bootstrap";

//layout
import Layout from "../Layout";

//static data
import bgCareer from "../../assets/images/career-header.png";

//translate
import { useTranslation } from "react-i18next";
import InternRMS from "./InternRMS";

const LayoutCareer = ({ children }) => {
  const { t } = useTranslation();

  return (
    <section>
      <Layout>
        <section className="bg-testimonial">
          <Container className="py-5">
            <Row
              className="wow animate__animated animate__fadeIn"
              data-wow-duration="2s"
            >
              <Col md={10} lg={7} className="text-white ">
                <h2 className="fw-bold mt-5 wow animate__animated animate__fadeIn">
                  {t("title_career_header")}
                </h2>
                <br />
                <h5>{t("detail_career_header")}</h5>
              </Col>
            </Row>
            <br />
          </Container>
        </section>

        <section style={{ backgroundColor: "#f2f5f7" }}>
          <Container style={{ padding: "7%" }}>
            <Row>
              <Col data-wow-duration="1s">
                <section style={{ marginTop: "-8rem" }}>
                  <Row className="director-prim">
                    <img
                      src={bgCareer}
                      className="d-block w-100"
                      alt=""
                      style={{ borderRadius: "10px" }}
                    />
                    <div className="btn-career-header">
                      <a
                        href="https://linktr.ee/info_rms"
                        className="btn-header-organization nav-link text-white fw-bold"
                      >
                        {t("btn_career_header")}
                      </a>
                    </div>
                  </Row>
                  <br />
                  <hr />
                  <br />
                  <Row
                    className="text-center fw-bold"
                    style={{ marginTop: "1rem" }}
                  >
                    <Col className="text-start" >
                      <h5 className="fs-2">{t("btn_intern")}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <InternRMS />
                  </Row>
                </section>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    </section>
  );
};

export default LayoutCareer;
