import { Col, Container, Row, Card, Carousel } from "react-bootstrap";
import axios from "axios";
import { useEffect, useState } from "react";
import React from 'react'

//static data
// import { _achievement } from "../static/achievement";

//translate
import { useTranslation } from "react-i18next";
import i18n from "../i18n.jsx";

function RMSPortfolio() {
  const { t } = useTranslation();
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    axios
      .get("https://rms.co.id/rms-api/api/portofolios")
      .then((res) => {
        setPortfolio(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <section className="bg-testimonial" style={{ marginBottom: "400px" }}>
      <Container className="py-5" style={{ marginBottom: "-10rem" }}>
        <Row
          className="wow animate__animated animate__fadeIn"
          data-wow-duration="2s"
        >
          <Col md={10} lg={7} className="text-white ">
            <h2 className="fw-bold mt-5 wow animate__animated animate__fadeIn">
              Portfolio PT Rukun Mitra Sejati
            </h2>
            <br />
          </Col>
        </Row>
        <br />
        <Row
          className="justify-content-center"
          style={{
            marginBottom: "-16rem",
            marginLeft: "20px",
            marginRight: "20px"
          }}
        >
          {portfolio.slice(0, 3).map((data, i) => (
            <Col
              md={6}
              lg={4}
              key={i}
              className="wow animate__animated animate__fadeIn"
              data-wow-delay={`${(i * 2) / 10}s`}
              style={{ marginBottom: "2rem" }}

            >
              <Card className="card-header-portfolio h-100">
                  <div className="zoom-achievement">
                    <Card.Img
                      variant="top"
                      src={`https://rms.co.id/rms-api/storage/app/public/images/portofolio/${data.gambar}`}
                      className="img-portfolio"
                    />
                  </div>
                <Card.Body>
                <h3 className="fw-bold">{i18n.language == 'id' ? data.judul : data.judul_en}</h3>
                      <br />
                      <p className="text-muted justified-paragraph">{i18n.language == 'id'  ? data.isi : data.isi_en}</p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default RMSPortfolio;
