import { Container, Row, Col } from "react-bootstrap";

// Icons
import { FiMail, FiPhoneCall } from "react-icons/fi";
import React from 'react'

// assets
import logo from "../assets/images/logo-rms-white.svg";

const FooterRMS = () => {
  const e = new Date();
  return (
    <footer className="" id="footer">
      <div className="bg-stone py-5">
        <Container>
          <Row>
            <Col md={12} className="mb-4 mb-lg-0 text-center">
              <img alt="" src={logo} />
              <p className="fw-bold text-white mt-4 mb-0">
                Boom Group Head Office
              </p>
              <p style={{ color: "#b1b1b1" }}>
                Jl. Sunandar Priyo Sudarmo No. 45 (Ruko No.45 G/H/I)
                <br />
                Blimbing Malang, Jawa Timur - Indonesia 65126
              </p>

              <div className="text-center text-white mt-4">
                <FiMail className="icon-footer" size={20} />
                <a
                  href="mailto:contact@rms.co.id"
                  className="ms-3 text-white"
                  style={{ marginTop: "-3px" }}
                >
                  contact@rms.co.id
                </a>
              </div>

              {/* Temporary Removal */}
              {/* <div className="text-center text-white mt-4">
                <FiPhoneCall className="icon-footer" size={20} />
                <a
                  href="tel:+628113697011"
                  className="ms-3 text-white"
                  style={{ marginTop: "-3px" }}
                >
                  +628113697011
                </a>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
      <hr className="divider" />
      <div className="bg-stone py-3">
        <Container className="text-center">
          <span className="copyright text-light">
            PT RUKUN MITRA SEJATI © 2023
          </span>
        </Container>
      </div>
    </footer>
  );
};

export default FooterRMS;
