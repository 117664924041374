import axios from "axios";
import dateFormatIndonesia from "../utility/date-formatter";

class CareerApiService {
  static #baseUrl =
    "https://firestore.googleapis.com/v1/projects/rms-proj/databases/(default)/documents";

    // For front pages
  static async getAllJobs(pageSize, nextPageToken) {
    const result = await axios.get(`${this.#baseUrl}/job_vacancies`);
    const mappedJobs = result.data.documents.map((document) => ({
        job_poster: document.fields.job_poster.stringValue,
        job_title: document.fields.job_title.stringValue,
        job_placement: document.fields.job_placement.stringValue,
        job_scheme: document.fields.scheme.stringValue,
        job_deadline: new Date(document.fields.job_deadline.timestampValue).toLocaleDateString(),
        job_slug: document.fields.job_slug.stringValue
    }))
    return mappedJobs;
  }

  static async getJobsByScheme(scheme, pageSize, nextPageToken) {
    const result = await axios.post(
      `https://firestore.googleapis.com/v1/projects/rms-proj/databases/(default)/documents:runQuery`,
      {
        structuredQuery: {
          from: [
            {
              collectionId: "job_vacancies",
              allDescendants: true,
            },
          ],
          where: {
            fieldFilter: {
              field: {
                fieldPath: "scheme",
              },
              op: "EQUAL",
              value: {
                stringValue: scheme,
              },
            },
          },
        },
      }
    );
    // const pageTokens = {};
    return result;
  }

  // for detail page
  static async getJobDetail(slug) {

    const result = await axios.post(
      `https://firestore.googleapis.com/v1/projects/rms-proj/databases/(default)/documents:runQuery`,
      {
        structuredQuery: {
          from: [
            {
              collectionId: "job_vacancies",
              allDescendants: true,
            },
          ],
          where: {
            fieldFilter: {
              field: {
                fieldPath: "job_slug",
              },
              op: "EQUAL",
              value: {
                stringValue: slug,
              },
            },
          },
        },
      }
    );
    // const data = result.data[0].document;
    const response = {
      job_path: result.data[0].document.name,
      job_info: {
        job_title: result.data[0].document.fields.job_title.stringValue,
        job_detail: result.data[0].document.fields.job_detail.stringValue,
        job_deadline: dateFormatIndonesia(result.data[0].document.fields.job_deadline.timestampValue).tampilTanggal,
        job_scheme: result.data[0].document.fields.scheme.stringValue,
        job_tags: result.data[0].document.fields.tags.arrayValue.values.map(tag => tag.stringValue),
        job_poster: result.data[0].document.fields.job_poster.stringValue,
        job_posted: dateFormatIndonesia(result.data[0].document.fields.job_posted.timestampValue).tampilTanggal,
        job_placement: result.data[0].document.fields.job_placement.stringValue,
        job_apply_link: result.data[0].document.fields.job_apply_link.stringValue,
        job_qualification: result.data[0].document.fields.job_qualification.arrayValue.values.map(qualification => qualification.stringValue),
        job_description: result.data[0].document.fields.job_description.arrayValue.values.map(description => description.stringValue),
      }
    }
    return response;
  }
}

export default CareerApiService;
