import { Col, Container, Row, Card } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import React from "react";

//assets
import wavetop from "../assets/images/wave-top.svg";
import logoRMS from "../assets/images/logo-rms-white.svg";

//static data
import { _OurStory } from "../static/our_story";
import { _OurStory_en } from "../static/our_story";

//translate
import { useTranslation } from "react-i18next";
import GlobalVariable from "./GlobalVariable";

function ModalStoryRMS() {
  const { t } = useTranslation();
  const bahasa = GlobalVariable.lang;
  let story = "";

  if (bahasa === "id") {
    story = _OurStory;
  } else {
    story = _OurStory_en;
  }
  return (
    <>
      <section className="pt-5 bg-history">
        <Container className="pt-5">
          <HashLink to={"/about-us#history"} style={{ textDecoration: "none" }}>
            <h2 className="fw-bold text-white mb-3 text-center">
              {t("title_rms_story")}
            </h2>
          </HashLink>
          <div className="bb-2" />
          <Row
            className="my-5"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {story.map((data, i) => (
              <Col
                key={i}
                className="my-2 wow animate__animated animate__fadeIn"
                data-wow-delay={`${(i * 2) / 10}s`}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                lg={4}
              >
                <Card
                  style={{
                    backgroundColor: "#EFEFEF",
                    borderRadius: "10px",
                  }}
                  className="img-fluid our-story"
                >
                  <Card.Body
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      overflow: "hidden",
                    }}
                  >
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <Row className="result-story">
                          <Col>
                            <img
                              src={data.img}
                              alt=""
                              style={{ width: "110px" }}
                            />
                          </Col>
                          <Col style={{ marginLeft: "-40px" }}>
                            <h5 className="fw-bold">{data.title}</h5>
                          </Col>
                          <div className="overlay-detail-story">
                            <p className="detail-story">{data.detail}</p>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Card className="card-story text-center fw-bold">
                      <h5 style={{ marginTop: "5px" }}>{data.year}</h5>
                    </Card>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        <img src={wavetop} className="w-100" alt="" />
      </section>
    </>
  );
}

export default ModalStoryRMS;
