export const _textVideo = [
  {
    id: 1,
    title: "We’re RMS, leading the distribution network in Indonesia by combine the technology inside the channel",
  },
  {
    id: 2,
    title: "Excellent distribution process, optimize promotion activity, target oriented, and collection effectiveness",
  },
  {
    id: 3,
    title: "Strong and warm communication and relationship with stakeholder",
  },
  {
    id: 4,
    title: "Presenting a good service level in every point as an actor who responsible to distribute product from principle to the market",
  }
];

export const _textVideo_id = [
  {
    id: 1,
    title: "Kami adalah RMS, yang memimpin jaringan distribusi di Indonesia dengan menggabungkan teknologi di dalam Channel",
  },
  {
    id: 2,
    title: "Proses distribusi yang sangat baik, mengoptimalkan kegiatan promosi, berorientasi pada target, dan efektivitas penagihan",
  },
  {
    id: 3,
    title: "Komunikasi dan hubungan yang kuat dan hangat dengan para pemangku kepentingan",
  },
  {
    id: 4,
    title: "Menghadirkan tingkat layanan yang baik di setiap titik sebagai Distributor yang bertanggung jawab untuk mendistribusikan produk dari prinsipal ke pasar",
  },
 
  
];
