import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

//layout
import Layout from "../../components/Layout";
import TestimonialRMS from "../../components/TestimonialRMS";
import RMSAchievement from "../../components/RMSAchievement";
import RMSPortfolio from "../../components/RMSPortfolio";

// assets
import wavetop from "../../assets/images/wave-top.svg";
import { Helmet } from "react-helmet-async";
//static data
export default class Portfolio extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Portfolio | RMS - Rukun Mitra Sejati</title>
          <meta
            name="description"
            content="RMS is FMCG Distributions Company Services with more than 10 Years experience to Handling FMCG Supply Chain."
          />
          <meta
            property="og:title"
            content="Portfolio | RMS - Rukun Mitra Sejati"
          />
          <meta
            property="og:description"
            content="RMS is FMCG Distributions Company Services with more than 10 Years experience to Handling FMCG Supply Chain."
          />
          <meta property="og:url" content="http://rms.co.id/portfolio" />
        </Helmet>
        <Layout>
          <RMSPortfolio />
          <section>
            <Container>
              <Row></Row>
            </Container>
          </section>
          <section className="bg-testimonial justify-content-center">
            <img src={wavetop} className="w-100 img-flip" alt="" />

            <Container>
              <Row>
                <TestimonialRMS />
              </Row>
            </Container>
          </section>

          <RMSAchievement />
        </Layout>
      </>
    );
  }
}
