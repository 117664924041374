import i18n from "../i18n.jsx";

const RMSOtherNews = ({ news }) => {
  return (
    <>
      <h6>{i18n.language === "id" ? "Sumber lain:" : "Other sources:"}</h6>
      <div className="d-flex flex-column">
        {news.map((newsSrc, index) => {
          return (
            <a href={newsSrc.news_url} target="_blank" rel="noreferrer" key={index}>
              {newsSrc.news_portal_name}
            </a>
          );
        })}
      </div>
    </>
  );
};

export default RMSOtherNews;
