import React, { useState } from "react";
import { Container, Card } from "react-bootstrap";

//layout
import ModalRMS from "./ModalRMS";

//static data
import { _TimelineCompany } from "../static/timeline_company";
import { _TimelineCompany_en } from "../static/timeline_company";

//translate
import { useTranslation } from "react-i18next";
import GlobalVariable from "./GlobalVariable";

const HistoryRMS = () => {
  const { t } = useTranslation();
  const [model, setModel] = useState(false);
  const [tempdata, setTempdata] = useState([]);

  const getData = (name, img) => {
    let tempData = [name, img];
    setTempdata(() => [1, ...tempData]);
    return setModel(true);
  };

  const bahasa = GlobalVariable.lang;
  let timeline = '';
  
 
  if (bahasa === 'id') {
    timeline = _TimelineCompany;
  } else {
    timeline = _TimelineCompany_en; 
  }


  return (
    <section
      id="history"
      className="py-5"
      style={{ backgroundColor: "#dee2e6" }}
    >
      <Container className="mt-5">
        <h2 className="text-center fw-bold mt-5 wow animate__animated animate__fadeIn ">
          {t("title_history")}
        </h2>
        <div className="bb-3" />
        <Container style={{ marginTop: "5rem" }}>
          <ul
            className="timeline wow animate__animated animate__fadeIn"
            data-wow-delay="0.5s"
          >
            {timeline.map((data, i) => (
              <div
                className="timeline-inverted wow animate__animated animate__fadeIn"
                data-wow-delay={`${(i * 2) / 10}s`}
              >
                <div className="timeline-badge"></div>
                <Card className="timeline-panel">
                  <Card.Img
                    variant="top"
                    src={data.img}
                    className="img-timeline"
                    onClick={() => getData(data.name, data.img)}
                  />
                  <Card.Body>
                    <h5 className="timeline-title fw-bold">{data.title}</h5>
                    <p>
                      <small className="text-muted">
                        <i className="glyphicon glyphicon-time"></i> {data.year}
                      </small>
                    </p>
                    <div className="timeline-body">
                      <p className="justified-paragraph">{data.detail}</p>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </ul>
        </Container>
      </Container>
      {model === true ? (
        <ModalRMS
          name={tempdata[1]}
          img={tempdata[2]}
          hide={() => setModel(false)}
        />
      ) : (
        ""
      )}
    </section>
  );
};

export default HistoryRMS;
