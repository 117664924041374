// Principal Logo
import principal1 from "../assets/images/logo/logo-unilever.png";
import principal2 from "../assets/images/logo/logo-nestle.png";
import principal3 from "../assets/images/logo/logo-unicharm.png";
import principal4 from "../assets/images/logo/logo-perfetti2.png";
import principal5 from "../assets/images/logo/logo-dua-kelinci.png";
import principal6 from "../assets/images/logo/logo-kraft.png";
import principal7 from "../assets/images/logo/logo-inl.png";
import principal8 from "../assets/images/logo/logo-fokus-ritel-ind.png";
import principal9 from "../assets/images/logo/logo-sns-group.png";


// Finance Logo
import finance1 from "../assets/images/logo/logo-grosirone.png";

export const _listFinance = [
  {
    img: finance1,
    // url:"https://grosir.one/",
  }
];

export const _listPrincipal = [
  {
    img: principal1,
    title: "Unilever",
    // url:"https://www.unilever.co.id/",
  },
  {
    img: principal2,
    title: "Nestle",
    // url:"https://www.nestle.co.id/",
  },
  {
    img: principal3,
    title: "Unicharm",
    // url:"https://www.unicharm.co.id/",
  },
  {
    img: principal4,
    title: "Perfetti",
    // url:"https://www.perfettivanmelle.com/",
  },
  {
    img: principal5,
    title: "Dua Kelinci",
    // url:"https://duakelinci.com/",
  },
  // {
  //   img: principal6,
  //   title: "Kraft Heinz",
  //   // url:"https://www.kraftheinzcompany.com/",
  // },
  // {
  //   img: principal7,
  //   title: "INL",
  //   // url:"https://inl.co.id/",
  // },
  {
    img: principal8,
    title: "PT. Fokus Ritel Indoprima",
    // url:"https://companieshouse.id/fokus-ritel-indoprima",
  },
  // {
  //   img: principal9,
  //   title: "PT. Sinar Niaga Sejahtera",
  //   // url:"https://www.linkedin.com/company/pt-sinar-niaga-sejahtera/",
  // },

];
