import historyA26 from "../assets/images/history/historyA26.JPG";
import historyBoom from "../assets/images/history/historyBoomMotorist.png";
import historyFMCG from "../assets/images/history/malang.jpg";
import history2017 from "../assets/images/history/gorontalo.jpg";
import history2023 from "../assets/images/history/tgr.jpg";
import { month, month_id } from "./months";

const current = new Date();

export const _TimelineCompany = [
  {
    img: historyA26,
    title: "Dimulai dengan didirikannya bisnis keluarga Toko A26 pada tahun 1970 di Malang oleh Bapak Lie Hendy Lianto.",
    year: "1970",
    detail:
      "Perjalanan dimulai saat keluarga Bapak Lie Hendy Lianto mendirikan bisnis dagang dengan nama Toko A26 yang bertempat di area pasar besar Kota Malang, Jawa Timur pada tahun 1970. Keluarga Bapak Lie Hendy memulai bisnisnya dengan mendistribusikan produk-produk makanan dan minuman yang banyak dicari oleh masyarakat. Sejak awal dirinya mempunyai keyakinan yang kuat bahwa bisnis dagang yang ia dan keluarganya kelola akan berkembang dan perlahan akan dikenal oleh masyarakat luas. ",
  },
  {
    img: historyBoom,
    title: "Berkembang bisnis Grosir Snack dengan nama BOOM Motorist yang didirikan pada 2006.",
    year: "2006",
    detail:
    "Seiring berjalannya waktu, bisnis perdagangan yang dikelola semakin berkembang dengan cakupan yang juga lebih besar. Keluarga Bapak Lie Hendy membuka unit bisnis baru berupa grosir snack pada tahun 2006 dengan nama BOOM Motorist. Sesuai dengan namanya, BOOM Motorist dijalankan dengan model bisnis jemput bola melalui salesman motorist yang mengcover area malang dan sekitarnya.",
  },
  {
    img: historyFMCG,
    title: "Distributor FMCG (Fast Moving Consumer Goods) pertamanya dengan nama PT Rukun Mitra Sejati pada 2009 di Malang.",
    year: "2009",
    detail:
    "Tidak berhenti sampai disitu. Dengan tekad, usaha, dan keyakinan yang kuat, pada tahun 2009 Bapak Lie Hendy Lianto mendirikan unit bisnis lain di bidang distributor Fast Moving Consumer Goods (FMCG) yang dinamainya PT Rukun Mitra Sejati. Unit Bisnis inilah yang mengantarkan pada kesuksesannya saat ini. Berbekal pengalaman di dunia perdagangan produk Fast Moving Consumer Goods, berbagai macam tantangan dalam menjalankan bisnis ini dapat dihadapi. Ditambah dengan kehadiran keluarga yang supportif dan tim yang solid, PT Rukun Mitra Sejati semakin menunjukkan perkembangan yang signifikan.",
  },
  {
    img: history2017,
    title: "Tahun 2017, merger dengan perusahaan Distributor FMCG asal Gorontalo milik Bapak Herry Ruslim.",
    year: "2017",
    detail:
    "Dalam berbisnis, tentu akan banyak pilihan yang perlu dipertimbangkan agar menjadi keputusan yang terbaik untuk keberlangsungan perusahaan. Keputusan tepat diambil oleh Bapak Lie Hendy Lianto, yakni pada tahun 2017 beliau memutuskan untuk bergabung – berkongsi dengan perusahaan distributor Fast Moving Consumer Goods asal Gorontalo milik Bapak Herry Ruslim. Dengan bergabungnya dua perusahaan di bidang distribusi ini, banyak tercipta strategi dan inovasi yang kemudian dikembangkan menjadi core value yang diterapkan dan dijunjung tinggi oleh seluruh karyawan PT Rukun Mitra Sejati. ",
  },
  {
    img: history2023,
    title: `PT Rukun Mitra Sejati ${current.getFullYear()}.`,
    year: current.getFullYear(),
    detail:
    `Hingga saat ini PT Rukun Mitra Sejati terus berkembang melebarkan sayap ke berbagai daerah di seluruh Indonesia. Di tahun ${current.getFullYear()}, PT Rukun Mitra Sejati sampai pada cabang ke-80nya. Kedepannya, PT Rukun Mitra Sejati akan terus berekspansi membuka titik baru dan mengembangkannya menjadi bisnis yang menguntungkan bagi para pemangku kepentingan. Serta membantu meningkatkan perekonomian dengan memberikan kesempatan pada masyarakat khususnya kaum milenial untuk bersaing dan bertumbuh mengembangkan karir bersama PT Rukun Mitra Sejati.`,
  },
  
];


export const _TimelineCompany_en = [
  {
    img: historyA26,
    title: "It started with the establishment of the family business Toko A26 in 1970 in Malang by Mr. Lie Hendy Lianto.",
    year: "1970",
    detail:
    "The journey began when Mr. Lie Hendy Lianto's family established a trading business under the name Toko A26 located in the large market area of Malang City, East Java in 1970. Mr. Lie Hendy's family started their business by distributing food and beverage products that were much sought after by the public. Since the beginning, he had a strong belief that the trading business that he and his family managed would grow and slowly be recognized by the wider community."  
    },
  {
    img: historyBoom,
    title: "Developed a wholesale snack business under the name BOOM Motorist which was established in 2006.",
    year: "2006",
    detail:
    "As time went by, the trading business that was managed grew with a larger scope. Mr. Lie Hendy's family opened a new business unit in the form of wholesale snacks in 2006 under the name BOOM Motorist. As the name implies, BOOM Motorist is run with a ball pick-up business model through motorist salesmen who cover the Malang and surrounding areas.",
  },
  {
    img: historyFMCG,
    title: "First FMCG (Fast Moving Consumer Goods) distributor under the name PT Rukun Mitra Sejati in 2009 in Malang.",
    year: "2009",
    detail:
    "It didn't stop there. With determination, effort, and strong belief, in 2009 Mr. Lie Hendy Lianto established another business unit in the field of Fast Moving Consumer Goods (FMCG) distributor called PT Rukun Mitra Sejati. It was this business unit that led to his current success. Armed with experience in the world of trading Fast Moving Consumer Goods products, various challenges in running this business can be faced. Coupled with the presence of a supportive family and a solid team, PT Rukun Mitra Sejati has shown significant growth." 
  },
  {
    img: history2017,
    title: "In 2017, merged with an FMCG Distributor company from Gorontalo owned by Mr. Herry Ruslim.",
    year: "2017",
    detail:
    "In business, of course there will be many choices that need to be considered in order to be the best decision for the sustainability of the company. The right decision was taken by Mr. Lie Hendy Lianto, in 2017 he decided to join forces with a Gorontalo-based Fast Moving Consumer Goods distributor company owned by Mr. Herry Ruslim. With the merger of these two companies in the distribution sector, many strategies and innovations were created which were then developed into core values that are applied and upheld by all employees of PT Rukun Mitra Sejati.",
  },
  {
    img: history2023,
    title: `PT Rukun Mitra Sejati ${current.getFullYear()}.`,
    year: current.getFullYear(),
    detail:
    `Until now, PT Rukun Mitra Sejati continues to grow and expand to various regions throughout Indonesia. In ${current.getFullYear()}, PT Rukun Mitra Sejati reached its 80th branch. In the future, PT Rukun Mitra Sejati will continue to expand to open new points and develop it into a profitable business for stakeholders. As well as helping to improve the economy by providing opportunities for the community, especially millennials, to compete and grow to develop careers with PT Rukun Mitra Sejati.`
  },
  
];
