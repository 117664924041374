import iconVision from "../assets/images/iconVision/organization.png"
import iconVision1 from "../assets/images/iconVision/process.png"
import iconVision2 from "../assets/images/iconVision/capability.png"
import iconVision3 from "../assets/images/iconVision/collaboration.png"

export const _Visi = [
  {
    title: "The Trusted Partner for Sustainable Growth and Profitable Business",
  },
];

export const _Visi_id = [
  {
    title: "Mitra Terpercaya untuk Pertumbuhan Berkelanjutan dan Bisnis yang Menguntungkan",
  },
];

export const _Misi = [
  {
    title: "Build Trust and Capabilities to Drive the Business by Double Digit Growth",
    detail: [
      {
        id: 1,
        title: "Effective Organization",
        img: iconVision,
        content:
          "Refocus on sales as driver & operational team as support function",
      },
      {
        id: 2,
        title: "Effective Business Process",
        img: iconVision1,
        content:
          "Upgrade standardized operational procedures (SOP) & Expansion with speed and control",
      },
      {
        id: 3,
        title: "People Capability & Talent Development",
        img: iconVision2,
        content:
          "New recruitment process and internal talent pool, Integrated incentive system (internal and external scheme), RMS Academy for people development",
      },
      {
        id: 4,
        title: "Strong Partnership & Collaboration",
        img: iconVision3,
        content:
          "Regular business review at all level, joint KPI & tracking, new business development",
      },
    ],
  },
];

export const _Misi_id = [
  {
    title: "Membangun Kepercayaan dan Kemampuan untuk Mendorong Pertumbuhan Bisnis Dua Digit",
    detail: [
      {
        id: 1,
        title: "Organisasi yang Efektif",
        img: iconVision,
        content:
          "Fokus kembali pada penjualan sebagai pendorong & tim operasional sebagai fungsi pendukung",
      },
      {
        id: 2,
        title: "Proses Bisnis yang Efektif",
        img: iconVision1,
        content:
          "Meningkatkan prosedur operasional standar (SOP) & Ekspansi dengan kecepatan dan kontrol",
      },
      {
        id: 3,
        title: "Pengembangan Kemampuan & Bakat Karyawan",
        img: iconVision2,
        content:
          "Proses rekrutmen baru dan kumpulan talenta internal, sistem insentif terintegrasi (skema internal dan eksternal), RMS Academy untuk pengembangan sumber daya manusia",
      },
      {
        id: 4,
        title: "Kemitraan & Kolaborasi yang Kuat",
        img: iconVision3,
        content:
          "Tinjauan bisnis secara berkala di semua tingkatan, KPI & pelacakan bersama, pengembangan bisnis baru",
      },
    ],
  },
];
