import React from 'react'
import { useState } from "react";
import { Row, Container, Card, Col, Modal } from "react-bootstrap";

const Vacancies = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <section>
      <Container>
        <div className=" wow animate__animated animate__fadeIn">
          <Row>
            <Col>
              <h4 className="fw-bold text-center wow animate__animated animate__fadeIn">
                {props.judul} 
              </h4>
              <Card>
                <img src={`https://rms.co.id/rms-api/storage/app/public/images/career/${props.imgBanner}`} alt="" onClick={handleShow} />
              </Card>
              <p className="text-muted justified-paragraph" style={{marginTop: "15px"}}>{props.deskripsi}</p>
            </Col>
          </Row>
          <br />
          <span />
        </div>
        <Modal centered show={show} onHide={handleClose}>
          <img src={`https://rms.co.id/rms-api/storage/app/public/images/career/${props.imgBanner}`} alt="" onClick={handleShow} />
        </Modal>
      </Container>
    </section>
  );
};

export default Vacancies;
