import { Container, Row, Col, NavItem, NavLink } from "react-bootstrap";
import BlogCard from "./BlogCard";
import { useEffect, useState } from "react";
import BlogApiService from "../../api/blog-api";
import { dotPulse } from "ldrs";

dotPulse.register();

// Default values shown

const BlogList = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    async function getAllBlogs() {
      const categoryResult = await BlogApiService.getBlogCategories();
      setCategories(new Set(categoryResult));
    }
    getAllBlogs();
  }, []);

  if (!categories) {
    return (
      <>
        <h1>Loading...</h1>
      </>
    );
  }

  const handleClick = (id) => {
    setActiveTab(id);
  };

  return (
    <>
      <Container id="bloglist" className="h-auto">
        {/* tab search */}
        <Row className="mt-5 mb-3">
          <div className="d-flex w-100 justify-content-center">
            <h3 className="fs-2">View All of Our Blogs</h3>
          </div>
        </Row>
        <Row className="px-5">
          <Col className="col-12 col-md-8 pe-md-5 tabbable">
            <ul class="nav nav-tabs">
              <NavItem class="nav-item">
                <NavLink
                  class="active"
                  active={activeTab === "All"}
                  aria-current="page"
                  onClick={() => handleClick("All")}
                >
                  All Blogs
                </NavLink>
              </NavItem>
              {[...categories].map((category, index) => (
                <NavItem class="nav-item" key={index}>
                  <NavLink
                    active={activeTab === category}
                    aria-current="page"
                    onClick={() => handleClick(category)}
                  >
                    {category}
                  </NavLink>
                </NavItem>
              ))}
            </ul>
          </Col>
          <Col className="col-12 col-md-4 d-flex justify-content-end gap-2 mt-2 mt-">
            <input
              placeholder="Search Blogs"
              className="flex-fill ml-md-4"
            ></input>
            <button className="btn btn-primary px-4 text-white">Search</button>
          </Col>
        </Row>

        <Row xs="1" sm="2" lg="3" className="g-4 mt-2">
          <Blogs category={activeTab} />
        </Row>
      </Container>
    </>
  );
};

const Blogs = ({ category }) => {
  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    async function getBlogs(categoryParam) {
      setBlogs(null);
      if (category === "All") {
        const blogData = await BlogApiService.getAllBlogs();
        setBlogs(blogData);
        return;
      }
      const blogsByCategory = await BlogApiService.getBlogsByCategory(
        categoryParam
      );
      setBlogs(blogsByCategory);
    }
    getBlogs(category);
  }, [category]);

  if (!blogs) {
    return (
      <div className="w-100 d-flex justify-content-center p-2">
        <l-dot-pulse size="43" speed="1.3" color="blue"></l-dot-pulse>
      </div>
    );
  }

  return (
    <>
      {blogs.map((item, index) => (
        <Col key={index}>
          <BlogCard blog={item} />
        </Col>
      ))}
    </>
  );
};

export default BlogList;
