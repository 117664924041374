import axios from "axios";

class BlogApiService {
  static #baseUrl =
    "https://firestore.googleapis.com/v1/projects/rms-proj/databases/(default)/documents";

  static async getAllBlogs(pageSize, nextPageToken) {
    try {
      const result = await axios.post(
        `https://firestore.googleapis.com/v1/projects/rms-proj/databases/(default)/documents:runQuery`,
        {
          structuredQuery: {
            from: [
              {
                collectionId: "blog",
                allDescendants: true,
              },
            ],
            where: {
              fieldFilter: {
                field: {
                  fieldPath: "status",
                },
                op: "EQUAL",
                value: {
                  stringValue: "published",
                },
              },
            },
          },
        }
      );

      // console.log(result.data);
      const mappedBlogs = await Promise.all(
        result.data.map(async (data) => {
          const categoryPath = data.document.fields.category.referenceValue;
          const category = await this.#getBlogCategory(categoryPath);

          return {
            blog_title: data.document.fields.name.stringValue,
            blog_slug: data.document.fields.slug.stringValue,
            blog_category: category,
            blog_description: data.document.fields.short_summary.stringValue,
            blog_publish_status: data.document.fields.status.booleanValue,
            published_date: data.document.fields.publish_date.timestampValue,
            blog_tags: data.document.fields.tags.arrayValue.values.map(
              (tag) => tag.stringValue
            ),
            blog_thumbnail: data.document.fields.thumbnail.stringValue,
            blog_contents: data.document.fields.content.arrayValue.values.map(
              (content) => content.mapValue.fields.value.stringValue
            ),
          };
        })
      );
      return mappedBlogs;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  static async getRecentBlogs() {
    try {
      const blogs = await this.getAllBlogs();
      const sortedBlogs = blogs.sort((a, b) => b - a);
      return sortedBlogs.slice(0, 2);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  static async getBlogBySlug(slug) {
    try {
      const blogs = await this.getAllBlogs();
      const blogResult = blogs.filter((blog) => blog.blog_slug === slug);

      return blogResult[0];
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  static async getBlogCategories() {
    try {
      const blogs = await this.getAllBlogs();
      const blogCategories = blogs.map((blog) => blog.blog_category);
      return blogCategories;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  static async #getBlogCategory(categoryPath) {
    try {
      const rootUrl = "https://firestore.googleapis.com/v1/";
      const categoryResult = await axios.get(`${rootUrl}${categoryPath}`);
      return categoryResult.data.fields.category_name.stringValue;
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  static async getBlogsByCategory(category) {
    try {
      const blogs = await this.getAllBlogs();
      const blogByCategory = blogs.filter(
        (blog) => blog.blog_category === category
      );
      return blogByCategory;
    } catch (error) {
      console.error(error);
      return {};
    }
  }
}

export default BlogApiService;
