import React, { useState, Suspense } from "react";
import { Container, Navbar, ButtonGroup, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

//translate
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "../i18n";

//assets
import logoRMS from "../assets/images/logo-rms.svg";

//static data
import { _Languages } from "../static/languages";
import GlobalVariable from "./GlobalVariable";
import AnnouncementBanner from "./AnnouncementBanner";

const NavbarRMS = () => {
  const [activeMenu] = useState(null);
  const { t } = useTranslation();
  const handleLanguageChange = (languageCode) => {
    i18next.changeLanguage(languageCode);
  };

  const handleImageClick = (languageCode) => {
    handleLanguageChange(languageCode);
    GlobalVariable.lang = languageCode;
    console.log("handleImageClick called with languageCode:", languageCode);
    console.log("GlobalVariable.lang:", GlobalVariable.lang);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Suspense fallback="Loading...">
      <Navbar expand="lg" className="navbar-landing sticky-top p-4">
        <Navbar.Brand>
          <NavLink to={"/"} onClick={scrollToTop} className="nav-link">
            <img src={logoRMS} alt="" className="img-navbar" />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="rms-nav" />
        <Navbar.Collapse id="rms-nav">
          <NavLink
            to="/"
            onClick={scrollToTop}
            className={`nav-menu nav-link ${
              "/" === activeMenu ? "active" : ""
            }`}
          >
            {t("home")}
          </NavLink>
          <NavLink
            to="/about-us"
            onClick={scrollToTop}
            className={`nav-menu nav-link ${
              "/about-us" === activeMenu ? "active" : ""
            }`}
          >
            {t("aboutUs")}
          </NavLink>
          <NavLink
            to="/portfolio"
            onClick={scrollToTop}
            className={`nav-menu nav-link ${
              "/portfolio" === activeMenu ? "active" : ""
            }`}
          >
            {t("portfolio")}
          </NavLink>
          <NavLink
            to="/organization"
            onClick={scrollToTop}
            className={`nav-menu nav-link ${
              "/organization" === activeMenu ? "active" : ""
            }`}
          >
            {t("organization")}
          </NavLink>
          <NavLink
            to="/branch"
            onClick={scrollToTop}
            className={`nav-menu nav-link ${
              "/branch" === activeMenu ? "active" : ""
            }`}
          >
            {t("branch")}
          </NavLink>
          <NavLink
            to="/career"
            onClick={scrollToTop}
            className={`nav-menu nav-link ${
              "/career" === activeMenu ? "active" : ""
            }`}
          >
            {t("career")}
          </NavLink>
          <NavLink
            to="/news"
            onClick={scrollToTop}
            className={`nav-menu nav-link ${
              "/news" === activeMenu ? "active" : ""
            }`}
          >
            {t("news")}
          </NavLink>
          {/* <NavLink
            to="/blogs"
            onClick={scrollToTop}
            className={`nav-menu nav-link ${
              "/blogs" === activeMenu ? "active" : ""
            }`}
          >
            {t("blogs")}
          </NavLink> */}
          <NavLink
            to="/contact-us"
            onClick={scrollToTop}
            className={`nav-menu nav-link ${
              "/contact-us" === activeMenu ? "active" : ""
            }`}
          >
            {t("contactUs")}
          </NavLink>

          <NavLink className="ms-auto">
            <ButtonGroup className="languages">
              {_Languages.map(({ code, img }) => (
                <Button
                  key={code}
                  value={code}
                  className="nav-link p-2"
                  variant="link"
                  onClick={() => handleImageClick(code)}
                >
                  <img src={img} width="42px" alt="" />
                </Button>
              ))}
            </ButtonGroup>
          </NavLink>
        </Navbar.Collapse>
      </Navbar>
      <AnnouncementBanner />
    </Suspense>
  );
};

export default NavbarRMS;
