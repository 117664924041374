import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Layout from "../../components/Layout";
import CareerApiService from "../../api/career-api";
import JobVacancies from "./lowongan-kerja";
import LayoutCareer from "../../components/career/LayoutCareer";

const CareerPage = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const jobResults = await CareerApiService.getAllJobs();
      setJobs(jobResults);
    }
    fetchData();
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    console.log(form);
  };

  return (
    <>
      <Helmet>
        <title>Career | RMS - Rukun Mitra Sejati</title>
        <meta
          name="description"
          content="RMS is FMCG Distributions Company Services with more than 10 Years experience to Handling FMCG Supply Chain."
        />
        <meta property="og:title" content="Career | RMS - Rukun Mitra Sejati" />
        <meta
          property="og:description"
          content="RMS is FMCG Distributions Company Services with more than 10 Years experience to Handling FMCG Supply Chain."
        />
        <meta property="og:url" content="http://rms.co.id/career" />
      </Helmet>
      <LayoutCareer />
    </>
  );
};

export default CareerPage;
