import storyA26 from "../assets/images/our_story/toko-a26.png";
import storyBoomMotorist from "../assets/images/our_story/boom-motorist.png";
import storyDistributor from "../assets/images/our_story/distributor.png";
import storyMerger from "../assets/images/our_story/merger.png";
import story70Distributor from "../assets/images/our_story/70-distributor.png";
import { month, month_id } from "./months";

const current = new Date();

export const _OurStory = [
  {
    title: "Toko A26 Didirikan",
    year: "1970",
    img: storyA26,
    detail:
      "Dimulai dengan didirikannya Toko A26 pada tahun 1970 di Malang oleh Bapak Lie Hendy Lianto.",
  },
  {
    title: "1'ST Boom Motorist",
    year: "2007",
    img: storyBoomMotorist,
    detail:
      "Berkembang bisnis Grosir Snack dengan nama BOOM Motorist yang didirikan pada 2006.",
  },
  {
    title: "1'ST RMS Distributor",
    year: "2012",
    img: storyDistributor,
    detail:
      "Semakin berkembang dengan mendirikan bisnis Distributor FMCG (Fast Moving Consumer Goods) pertamanya dengan nama PT Rukun Mitra Sejati pada 2009 di Malang.",
  },
  {
    title: "Merger RMS pada tahun 2017",
    year: "2017",
    img: storyMerger,
    detail:
      "Tahun 2017, merger dengan perusahaan Distributor FMCG asal Gorontalo milik Bapak Herry Ruslim.",
  },
  {
    title: "100+ Distributor RMS",
    year: "Saat ini",
    img: story70Distributor,
    detail: `Hingga saat ini di bulan ${month_id[current.getMonth()]} ${
      current.getFullYear()
    }, PT Rukun Mitra Sejati terus berkembang melebarkan sayap ke berbagai daerah di seluruh Indonesia dengan 80 cabang dan akan terus berekspansi.`,
  },
];

export const _OurStory_en = [
  {
    title: "Toko A26 Established",
    year: "1970",
    img: storyA26,
    detail:
      "Starting with the establishment of A26 Store in 1970 in Malang by Mr. Lie Hendy Lianto.",
  },
  {
    title: "1'ST Boom Motorist",
    year: "2007",
    img: storyBoomMotorist,
    detail:
      "Developed a wholesale snack business under the name BOOM Motorist which was established in 2006.",
  },
  {
    title: "1'ST RMS Distributor",
    year: "2012",
    img: storyDistributor,
    detail:
      "It grew further by establishing its first FMCG (Fast Moving Consumer Goods) Distributor business under the name PT Rukun Mitra Sejati in 2009 in Malang.",
  },
  {
    title: "RMS Merger in 2017",
    year: "2017",
    img: storyMerger,
    detail:
      "In 2017, merged with an FMCG Distributor company from Gorontalo owned by Mr. Herry Ruslim.",
  },
  {
    title: "100+ RMS Distributor",
    year: "Now",
    img: story70Distributor,
    detail: `Until now in ${month[current.getMonth()]} ${
      current.getFullYear()
    }, PT Rukun Mitra Sejati continues to expand to various regions throughout Indonesia with its 80 branches and will continue to expand.`,
  },
];
