import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/home";
import AboutUs from "./pages/about-us";
import Portfolio from "./pages/portfolio";
import ContactUs from "./pages/contact-us";
import Organization from "./pages/organization";
import Branch from "./pages/branch";
import CareerPage from "./pages/career/career-old-temp";
import BlogPage from "./pages/blogs";
import News from "./pages/news";
import BlogDetail from "./components/blog/BlogDetail";
import CareerDetail from "./components/career/CareerDetail";

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />}></Route>
      <Route path="/about-us" element={<AboutUs />}></Route>
      <Route path="/portfolio" element={<Portfolio />}></Route>
      <Route path="/organization" element={<Organization />}></Route>
      <Route path="/branch" element={<Branch />}></Route>
      <Route path="/contact-us" element={<ContactUs />}></Route>
      <Route path="/career" element={<CareerPage />}></Route>
      {/* <Route path="/career/:slug" element={<CareerDetail />}></Route> */}
      {/* <Route path="/blogs" element={<BlogPage />}></Route> */}
      <Route path="/news" element={<News />}></Route>
      {/* <Route path="/blogs/:slug" element={<BlogDetail />}></Route> */}
    </Routes>
  );
}
