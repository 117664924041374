import React, { useState } from "react";
import { BaseModal } from "./base/BaseModal";
import { useTranslation } from "react-i18next";
import ImageCarousel from "./base/BaseCarousel";

const HighlightNewsDetail = () => {
  const { t } = useTranslation();
  const data = [
   'https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/nc%202024/vf02h23fvty37znalc1w',
   'https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/nc%202024/ua0cucxkmanjv4qjvhrh',
   'https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/nc%202024/ykpiywhvgmfz4khwg6nd',
   'https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/nc%202024/rygzfu74uhozchwhvveo',
   'https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/nc%202024/ksdancduhbmcleshujy7',
   'https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/nc%202024/zaacampqj6w1kpckwdsl',
   'https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/nc%202024/uqkuc7tld1qow3cero4l',
   'https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/nc%202024/ydx8z0445m9xhx8axfjd',
   'https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/nc%202024/hb6ysr3ulc4bdjbgycd3',
   'https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/nc%202024/cdvgjlxqbft13lb3fbnb'
  ];
  return (
    <>
      <div className="row g-0 px-2">
        <div className="col-lg-6">
          <ImageCarousel images={data} />
        </div>
        <div className="col-lg-6 ps-4">
          <h3 className="fw-bold">{t('nc_2024.title')}</h3>
          <h4 className="text-warning fs-5 mb-3">Haris Hotel, Malang</h4>
          <p>{t('nc_2024.paragraph1')}</p>
          <p>{t('nc_2024.paragraph2')}</p>
          <p>{t('nc_2024.paragraph3')} <span className="fw-bold">Level up, Collaboration</span> {t('nc_2024.paragraph3cont')} </p>
        </div>
      </div>
    </>
  );
};

export const HighlightNews = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  return (
    <>
      <section className="card p-4 rounded-3 overflow-hidden border-0 dark:focus:ring-1 dark:focus:ring-gray-600 shadow-sm">
        <div className="row g-0">
          <div className="col-md-6">
            <div className="ratio ratio-16x9">
            <iframe className="rounded" width="auto" height="auto" src="https://www.youtube.com/embed/WqVahADTQRg" title="RMS National Convention 2024" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-start py-4 px-4">
            <div className="flex-grow-1 ms-md-3">
              <h2 className="h2 fw-bold mb-3">{t('nc_2024.title')}</h2>
              <p className="opacity-75">03 Februari 2024</p>
              <h3 className="text-warning fs-5 mb-3">Haris Hotel, Malang</h3>
              <p className="text-muted mb-3">{t('nc_2024.description')}
              </p>
              <button
                onClick={handleShow}
                type="button"
                className="btn p-0 d-flex align-items-center text-info"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                // className=""
              >
                {t('nc_2024.btn')}
                <i className="fa fa-chevron-right mx-2"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <BaseModal
        show={show}
        handleClose={handleClose}
        content={<HighlightNewsDetail />}
      />
    </>
  );
};
