import React, { useEffect, useState } from "react";
import { InputGroup, Form, Button, Row, Col } from "react-bootstrap";

//translate
import { useTranslation } from "react-i18next";

//layout
import Vacancies from "./Vacancies";

//data static
import { _internVacancies } from "../../static/vacancies";
import axios from "axios";
import i18n from "../../i18n.jsx";


  
  
  function InternRMS() {
    const { t } = useTranslation();
  const [query, setQuery] = useState("");

    const [career, setCareer] = useState([]);
  
    useEffect(() => {
      axios
        .get("https://rms.co.id/rms-api/api/careers?type=Internship")
        .then((res) => {
      setCareer(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

  
  return (
    <section>
      <p>{t("desc_intern_vacancy")}</p>

      <Row>
        <InputGroup type="search" style={{ marginBottom: "3rem" }}>
          <Form.Control
            placeholder={t("placeholder_search")}
            aria-label="Search"
            onChange={(event) => setQuery(event.target.value)}
          />
          <Button variant="outline-primary" id="search">
            {t("btn_search")}
          </Button>
        </InputGroup>
        {career
          .filter((intern) => {            
            if (query === "") {
              return intern;
            } else if (          
              intern.judul.toLowerCase().includes(query.toLowerCase()) ||
              intern.deskripsi.toLowerCase().includes(query.toLowerCase())                        
            ) {
              return intern;
            }
          })
          .map((data, i) => (                        
            <Col lg={4}>
              <Vacancies
                judul={i18n.language == 'id' ? data.judul : data.judul_en}
                imgBanner={data.image}
                link={data.url}
                deskripsi={i18n.language == 'id' ? data.deskripsi : data.deskripsi_en}
              />
            </Col>
          ))}
      </Row>
    </section>
  );
};

export default InternRMS;
