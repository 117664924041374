import why1 from "../assets/images/why_choose_us/excellent.svg";
import why2 from "../assets/images/why_choose_us/trusted.png";
import why3 from "../assets/images/why_choose_us/partner.png";
import why4 from "../assets/images/why_choose_us/sustainability.png";

export const _whyChooseUs = [
  {
    title: "Excellent distribution process",
    content: "Excellent distribution process by optimizing promotional activities, Target orientation, Service Level 1x24 Hours to customers",
    img: why1,
  },
  {
    title: "Trusted and profitable business",
    content: "Trusted and relied upon in terms of service and product quality",
    img: why2,
  },
  {
    title: "Partner Engagement",
    content: "Build strong and warm communication and relationship with stakeholder",
    img: why3,
  },
  {
    title: "Sustainability Business",
    content: "Mutually beneficial long-term business partner",
    img: why4,
  },
];


export const _whyChooseUs_id = [
  {
    title: "Proses distribusi yang sangat baik",
    content: "Proses distribusi yang sangat baik dengan mengoptimalkan kegiatan promosi, Orientasi pada target, Service Level 1x24 Jam kepada pelanggan",
    img: why1,
  },
  {
    title: "Bisnis tepercaya dan menguntungkan",
    content: "Dipercaya dan diandalkan dalam hal layanan dan kualitas produk",
    img: why2,
  },
  {
    title: "Keterlibatan Mitra",
    content: "Membangun komunikasi dan hubungan yang kuat dan hangat dengan para pemangku kepentingan",
    img: why3,
  },
  {
    title: "Bisnis Keberlanjutan",
    content: "Mitra bisnis jangka panjang yang saling menguntungkan",
    img: why4,
  },
];
