import React, { useState, Suspense, useEffect } from "react";
import { Button, Col, Container, Row, Carousel } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";

//assets
import bgVideoWithText from "../assets/videos/bg-video-rms.mp4";

// icons
import { FaLongArrowAltRight } from "react-icons/fa";

//translate
import { useTranslation } from "react-i18next";

//data static
import { _textVideo } from "../static/text_video";
import { _textVideo_id } from "../static/text_video";

import GlobalVariable from "./GlobalVariable";

const VideoHeaderRMS = () => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const carouselDuration = 6600;

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const bahasa = GlobalVariable.lang;
  let text = '';

  if (bahasa === 'id') {
    text = _textVideo_id;

  } else {
    text = _textVideo;

  }
  return (
    <section>
      <Suspense fallback="Loading...">
        <header className="video-header">
          <video
            id="myVideo"
            src={bgVideoWithText}
            autoPlay
            muted
            loop
            playsInline
          ></video>

          <div className="viewport-header">
            <Container>
              <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                controls={false}
                indicators={false}
                interval={carouselDuration}
              >
                {text.slice(0, 4).map((data, i) => (
                  <Carousel.Item key={i}>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Row
                        className="wow animate_animated animate_fadeIn"
                        data-wow-duration="1s"
                      >
                        <Col md={10} lg={7}>
                          <h2 className="fw-bold video-text txt-shadow">
                            {data.title}
                          </h2>

                        </Col>
                      </Row>
                    </Row>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Container>
          </div>
        </header>
      </Suspense>
    </section>
  );
};

export default VideoHeaderRMS;